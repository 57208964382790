export type ClubListCardTypes = {
  additionalText?: string;
  price: string;
  services: string[];
  name: string;
  description: string;
  link: string;
}

export default function ClubListCard({ additionalText, price, services, name, description, link }: ClubListCardTypes) {
  return (
    <div className="club-list-card">
      <div className="club-list-card-header">
        <span className="club-list-card-header-prefix">Clube</span>
        <span className="club-list-card-header-title">{name}</span>
        <span className="club-list-card-header-description">{description}</span>
        <span className="club-list-card-header-price">
          {additionalText && <span className="club-list-card-header-price-description">3 serviços + 1 GRÁTIS</span>}
          <span className="club-list-card-header-price-currency">R$</span>
          <span className="club-list-card-header-price-value">{price}</span>
          <span className="club-list-card-header-price-period">/mês</span>
        </span>
      </div>
      <div className="club-list-card-content">
        <ul>
          {services.map((item) => <li key={item}>{item}</li>)}
        </ul>

        <a href={link} target="_blank" rel="noreferrer">Assinar</a>
      </div>
    </div>
  );
}
