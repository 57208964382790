import ClubListCard, { ClubListCardTypes } from "./ClubListCard";

type ClubListTypes = {
  cards: ClubListCardTypes[];
  title?: string;
}

export default function ClubList({ cards, title }: ClubListTypes) {
  return (
    <>
    {title && <div className="club-list-title">{title}</div>}
    <div className="club-list">
      <div className="club-list-container">
        {cards.map((card) => (
          <ClubListCard
            additionalText={card.additionalText}
            name={card.name}
            description={card.description}
            price={card.price}
            link={card.link}
            services={card.services}
          />
        ))}
        </div>
    </div>
    </>
  );
}
