import { Link } from "react-router-dom";
import ClubList from "../components/ClubList";
import isMobile from "../tools/is-mobile.tool";
import { useEffect, useState } from "react";

const nailClubCards = [
  {
    additionalText: '3 serviços + 1 GRÁTIS',
    description: 'Para iniciar sua jornada de autoconfiança e beleza',
    link: 'https://loja.infinitepay.io/clair-de-lune/cbu0001-clube-lua-nova',
    name: 'Lua Nova',
    price: '59,90',
    services: [
      'Serviços de manicure',
      '4 serviços mensais',
      'Preços exclusivos em outros serviços',
      'Renovação automática no cartão',
    ],
  },
  {
    description: 'Deixe sua beleza brilhar com uma rotina de cuidados exclusivos',
    link: 'https://loja.infinitepay.io/clair-de-lune/clu0002-clube-luz-do-encanto',
    name: 'Luz do Encanto',
    price: '89,90',
    services: [
      '4 serviços de manicure mensais',
      '2 serviços de pedicure mensais',
      'Preços exclusivos em outros serviços',
      'Renovação automática no cartão',
    ],
  },
  {
    description: 'Liberdade para viver suas melhores fases',
    link: 'https://loja.infinitepay.io/clair-de-lune/clu0003-clube-mulher-de-fases',
    name: 'Mulher de Fases',
    price: '119,90',
    services: [
      '4 serviços de manicure mensais',
      '4 serviços de pedicure mensais',
      'Preços exclusivos em outros serviços',
      'Renovação automática no cartão',
    ],
  },
];

const hairClubCards = [
  {
    additionalText: '3 serviços + 1 GRÁTIS',
    description: 'Impulsione, nutra e revitalize seus cabelos semanalmente',
    link: 'https://loja.infinitepay.io/clair-de-lune/clc0001-clube-crescente-poderosa',
    name: 'Crescente Poderosa',
    price: '149,90',
    services: [
      'Hidratação + escova',
      '4 serviços mensais',
      'Preços exclusivos em outros serviços',
      'Renovação automática no cartão',
    ],
  },
  {
    additionalText: '3 serviços + 1 GRÁTIS',
    description: 'Desperte o máximo potencial da força e vitalidade da beleza de seus cabelos',
    link: 'https://loja.infinitepay.io/clair-de-lune/clc0002-clube-cheia-de-forca',
    name: 'Cheia de Força',
    price: '239,90',
    services: [
      'Tratamentos BRAÉ / Wella',
      'Escova',
      '4 serviços mensais',
      'Preços exclusivos em outros serviços',
      'Renovação automática no cartão',
    ],
  },
]

export default function Home() {
  const [showChat, setShowChat] = useState(false);
  const [showChatText, setShowChatText] = useState(true);

  useEffect(() => {
    const chat = setTimeout(() => {
      setShowChat(true);
    }, 5_000);

    const chatText = setTimeout(() => {
      setShowChatText(false);
    }, 15_000);

    return () => {
      clearTimeout(chat);
      clearTimeout(chatText);
    }
  }, []);

  return (
    <div>
      <div className="home-banner" style={{ backgroundImage: `url('./assets/background.jpg')` }}>
        <div>
          <span>Primeiro salão de beleza<br /> por assinatura de Pedra de Guaratiba!</span>
        </div>
      </div>
      <div className="home-schedule">
        {isMobile() ? (
          <a href="https://www.trinks.com/clair-de-lune/" className="home-schedule">
            Agende online agora mesmo
          </a>
        ) : (
          <Link to="/agendar" className="home-schedule">
            Agende online agora mesmo
          </Link>
        )}
      </div>
      <div id="clubes" className="home-club-container" style={{ backgroundImage: `url('./assets/background-club-nails.jpg')` }}>
        <div className="home-club-title">Clubes para unhas 💅🏻</div>
        <div className="home-club-description-container">
          <span className="home-club-description">
            Garanta que suas unhas estarão impecáveis
            a todo momento com nossos clubes
            que te dão direito a manicure/pedicure
            semanalmente com baixo custo!
          </span>
        </div>
        <ClubList cards={nailClubCards} />
      </div>
      <div className="home-club-container"  style={{ backgroundImage: `url('./assets/background-club-hair.jpg')` }}>
        <div className="home-club-title">Clubes para cabelos 💇‍♀️</div>
        <div className="home-club-description-container">
          <span className="home-club-description">
            Tenha cabelos sempre brilhosos e saudáveis
            com rotinas de tratamentos exclusivas
            pensadas para as suas necessidades
            que só nossos clubes podem
            proporcionar!
          </span>
        </div>
        <ClubList cards={hairClubCards} />
      </div>
      <div className="informations">
        <a
          href="https://wa.me/5521986841208"
          target="_blank" rel="noreferrer"
          className="whatsapp"
        >
          <img src="./assets/icon-whatsapp.svg" alt="WhatsApp" />
          <span>(21) 98684-1208</span>
        </a>
        <a href="https://maps.app.goo.gl/C3dbqNhCqiEoEhpM7" target="_blank" rel="noreferrer">
          <img src="./assets/icon-map.svg" alt="Endereço" />
          <span>Estrada da Matriz, 320 - Loja 103 - Pedra de Guaratiba - Rio de Janeiro</span>
        </a>
      </div>
      {showChat && (
        <a
          href="https://wa.me/5521986841208?text=Olá!%20Vim%20pelo%20site%20e%20gostaria%20de%20tirar%20dúvidas!"
          target="_blank" rel="noreferrer"
          className="home-chat"
        >
          {showChatText && <span className="home-chat-text">Ficou com alguma dúvida? Fale conosco!</span>}
          <img src="./assets/icon-whatsapp.svg" alt="WhatsApp" />
        </a>
      )}
    </div>
  );
}
